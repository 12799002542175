/* Font by VileR: https://int10h.org/oldschool-pc-fonts/ */
@font-face {
  font-family: "ega";
  src: url("../fonts/ega.woff");
}

* {
  box-sizing: border-box;
}

body {
  background: #aaa;
  font-family: ega, Arial, sans-serif;
  font-size: 1.6rem;
}

nav {
  background: rgba(0, 0, 0, 0.75);
  color: #eef;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;
  padding: 0.8rem;
}

body nav a:hover,
body nav a:focus,
body nav a.active:hover,
body nav a.active:focus,
.portfolio a:hover,
.portfolio a:focus,
.invalid a:hover,
.invalid a:focus {
  text-decoration: underline dashed;
}

body nav a.active {
  text-decoration: underline;
}

nav .links {
  align-items: center;
  display: flex;
  gap: 8px;
}

.container {
  align-items: center;
  background-attachment: local;
  background-position: top right;
  background-size: cover;
  box-shadow: 0 0 5px #333;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1000px;
  min-height: 100vh;
}

.container .control {
  display: flex;
  gap: 16px;
}

.container .control button {
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: #f3f34e;
  cursor: pointer;
  font-family: ega, Arial, sans-serif;
  font-size: 1.6rem;
  padding: 0;
  transform: translateY(6px);
}

.container .control button:focus,
.container .control button:hover {
  border-bottom: 2px dashed #f3f34e;
}

.container .control-icon {
  height: 32px;
}

.container .control button:focus,
.container .control button:hover {
  text-decoration: underline dashed;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.hamster {
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	padding: 8px;
	gap: 32px;
  list-style-position: inside;
}

.hamster-header {
  font-size: 2rem;
}

.home nav a,
.portfolio nav a,
.invalid a {
  color: #f3f34e;
  text-decoration: none;
}

.home,
.portfolio {
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.8);
  max-width: 800px;
  overflow: auto;
}

.blink {
  animation: blinker 750ms step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.portfolio {
  max-height: 100vh;
  overflow-wrap: break-word;
  width: 100%;
}

.portfolio::-webkit-scrollbar {
  background-image: url("../images/scrollbar-background.png");
  width: 16px;
}

.portfolio::-webkit-scrollbar-thumb {
  background-image: url("../images/scrollbar-thumb.png");
}

.portfolio::-webkit-scrollbar-button {
  height: 32px;
}

.portfolio::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("../images/scrollbar-arrow-up.png");
}

.portfolio::-webkit-scrollbar-button:vertical:increment{
  background-image: url("../images/scrollbar-arrow-down.png");
}

.portfolio a {
  color: #0000c4;
  text-decoration: none;
}

.portfolio a img {
  border-bottom: 3px solid transparent;
  padding-bottom: 1px;
}

.portfolio a img:hover,
.portfolio a img:focus {
  border-bottom: 3px dashed #0000c4;
}

.portfolio h1 {
  font-size: 2rem;
  margin-left: 0.2rem;
}

.portfolio h2 {
  color: #4ef3f3;
  font-size: 1.8rem;
  margin-left: 0.2rem;
}

.portfolio h3 {
  color: #c40000;
}

.portfolio .about,
.portfolio .about-small,
.portfolio .contact,
.portfolio .contact .contact-info,
.portfolio .projects,
.portfolio .projects-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.portfolio .about .about-large {
  display: none;
}

.portfolio .contact .contact-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.portfolio .contact .contact-details .icons {
  display: flex;
  gap: 16px;
}

.portfolio .contact .details {
  font-size: 1.5rem;
}

.portfolio .contact .latin {
  color: #111;
  cursor: default;
  font-size: 1.3rem;
  font-style: italic;
}
.portfolio .contact .latin:hover {
  text-decoration: none;
}

.portfolio .image-container {
  aspect-ratio: 1 / 1;
  height: 100%;
  max-height: 416px;
  max-width: 416px;
  width: 100%;
}

.portfolio .about .headshot,
.portfolio .contact-photo {
  background-image: url("../images/sean.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #333;
  box-shadow: 5px 5px 0 #444;
  height: 100%;
  width: 100%;
}

.portfolio .contact .contact-photo {
  background-image: url("../images/hiking.png");
}

.portfolio .project {
  background-color: #c4c4c4;
  border: 1px solid #333;
  color: #001;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0.8rem;
  text-shadow: none;
}

.portfolio .project img.link-icon {
  height: 32px;
  transform: translateY(6px);
}

.portfolio .project .project-links,
.portfolio .project .project-title {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  max-width: 416px;
}

.portfolio .project .project-description,
.portfolio .contact .contact-info {
  max-width: 416px;
}

.portfolio .project .screenshot {
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #333;
  box-shadow: 5px 5px 0 #444;
  height: 100%;
  width: 100%;
}

.portfolio .skills {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.portfolio .skills ul {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  font-size: 0.9rem;
}

.portfolio .skills .skill-icon {
  background-size: cover;
  height: 40px;
  width: 40px;
  filter: brightness(0);
}

.project.blog-post {
  gap: 16px;
  white-space: pre-wrap;
}

.project.blog-post .comment {
  background: #eee;
  box-shadow: 5px 5px 0 #444;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 8px;
  padding: 8px;
  white-space: pre-wrap;
}

.project.blog-post .comment-form {
  background: #eee;
  box-shadow: 5px 5px 0 #444;
  color: #111;
  padding: 8px;
}

.project .error {
  background: #c40000;
  color: #eee;
  padding: 8px;
}

.project.blog-post .comment-form form,
.project.blog-post label {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.project.blog-post label {
  gap: 4px;
}

.project.blog-post .post-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.project.blog-post .published-info {
  padding: 8px;
}

.project.blog-post .published-info.no {
  background: #c47e00;
}

.project.blog-post .published-info.yes {
  background: #00c400;
}

.project.blog-post .post-control {
  display: flex;
  gap: 8px;
}

.project.blog-post .post-control.bottom {
  flex-direction: column;
}

.project.blog-post .comment-form form button,
.project.blog-post .post-control button {
  background: #0000c4;
  border: none;
  color: #eee;
  cursor: pointer;
  font-family: ega, Arial, sans-serif;
  font-size: 1.4rem;
  outline: none;
  padding: 8px;
  width: max-content;
}

.project.blog-post .post-control button.delete {
  background: #c40000;
}

.project.blog-post .post-control button.submit-edit{
  background: #c400c4;
}

.project.blog-post .post-control button:focus,
.project.blog-post .post-control button:hover,
.project.blog-post .comment-form form button:hover,
.project.blog-post .comment-form form button:focus {
  outline: 4px solid #001;
}

.project.blog-post input:hover,
.project.blog-post textarea:hover {
  outline: 4px solid #111;
}

.project.blog-post input:focus,
.project.blog-post textarea:focus {
  outline: 4px solid #0000c4;
}


.project.blog-post input,
.project.blog-post textarea {
  background: #eee;
  border: 2px solid #111;
  font-family: ega, Arial, sans-serif;
  font-size: 1.4rem;
  outline: none;
  padding: 4px;
}

.project h1.blog-title {
  color: #c40000;
  font-size: 32px;
  margin-left: 0;
}

.project .post-info {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  margin-left: 2rem;
  gap: 8px;
}

.project .post-info .post-timestamp {
  display: flex;
  flex-wrap: wrap;
  font-style: italic;
  gap: 8px;
}

.quote,
.info {
  background: rgba(16, 16, 16, 0.7);
  color: #eef;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.8rem;
  text-shadow: 2px 2px 0 #333;
}

.quote .author {
  font-size: 1.5rem;
  font-style: italic;
  margin-left: 2rem;
}

/* post editing "published" slider */
.switch {
  display: inline-block;
  height: 40px;
  position: relative;
  width: 64px;
}

.switch:hover,
.switch:focus {
  outline: 4px solid #111;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background: #c47e00;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .2s;
  -webkit-transition: .2s;
}

.slider:before {
  background: #eee;
  bottom: 6px;
  content: "";
  height: 28px;
  left: 5px;
  position: absolute;
  transition: .2s;
  width: 28px;
  -webkit-transition: .2s;
}

input:checked + .slider {
  background: #00c400;
}

input:focus + .slider {
  box-shadow: 0 0 1px #111;
}

input:checked + .slider:before {
  transform: translateX(26px);
  -ms-transform: translateX(26px);
  -webkit-transform: translateX(26px);
}

@media (min-width: 650px) {
  .portfolio .about .about-large {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .portfolio .about .about-small {
    display: none;
  }

  .portfolio .about .project {
    display: grid;
    gap: 16px;
    grid-template-columns: 50% 50%;
  }

  .portfolio .about h2 {
    color: #c40000;
  }

  .portfolio .contact .project {
    flex-direction: row;
    position: relative;
    padding-bottom: 2.2rem;
  }

  .portfolio .contact .contact-info {
    gap: 16px;
  }

  .portfolio .contact .latin {
    position: absolute;
    bottom: 0.1rem;
    left: 0.5rem;
  }
  
  .portfolio .projects {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .portfolio .projects .projects-grid {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto repeat(auto-fit, 1fr);
  }

  .portfolio .projects .project {
    display: grid;
    gap: 8px;
    grid-template-rows: auto 2rem 1fr;
  }

  .portfolio .skills ul {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(56px, 1fr));
  }
}
